import { useRouter } from 'next/router'
import { routes } from '../constants/routes'
import styled from '@emotion/styled'
import { Text, Button, Icon } from '@chakra-ui/react'
import { FaHeartBroken } from 'react-icons/fa'

const StyledCollectionNotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  height: 100vh;

  svg {
    color: red;
  }
`

const NotFoundPage = () => {
  const router = useRouter()

  return (
    <StyledCollectionNotFound>
      <Icon as={FaHeartBroken} boxSize="70px" />
      <Text fontSize="xl" mb="15px">
        Page was not found!
      </Text>
      <Text pb={20}>Looks like the page you are trying to access doesn&apos;t exist</Text>
      <Button color="secondary" onClick={() => router.push(routes.HOME)}>
        Back
      </Button>
    </StyledCollectionNotFound>
  )
}

export default NotFoundPage
